$breakpoints: ('xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px);

// keywords
$media-expressions: ('screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'vendors/include-media';

@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/colors';


:root {

    --blue            : #4471FF;
    --blue-very-light : #F5F7FF;
    --blue-light      : #D6EFFF;
    --white           : #FFFFFF;
    --grey-dark       : #121F29;
    --grey-semi-dark2 : #363D45;
    --grey-semi-dark3 : #656F78;
    --grey-semi-dark  : #888D95;
    --grey-semi-dark4 : #686c73;
    --grey            : #E5E5E5;
    --grey2           : #CACBCD;
    --grey-light      : #F2F2F2;
    --orange          : #FF9108;
    --orange-light    : #FFF1D0;
    --green-dark      : #0A8716;
    --green           : #2DA729;
    --green-light     : #EEF9EE;
    --purple-light    : #EDE5FF;
    --red             : #E84A4D;
    --yellow          : #F8DC00;

    --rgb-blue            : 68 113 255;
    --rgb-orange          : 255 145 8;
    --rgb-grey-dark       : 18 31 41;
    --rgb-grey-semi-dark3 : 101 111 120;
    --rgb-green-dark      : 10 135 22;
    --rgb-white           : 255 255 255;
    --rgb-black           : 0 0 0;
    --rgb-red             : 232 74 77;
    --rgb-green           : 45 167 41;
    --rgb-yellow          : 248 220 0;


    --primary-bg-color: var(--grey-light);
    --secondary-bg-color: var(--white);
    --secondary-bg-color-rgb: var(--rgb-white);
    --disabled-bg-color : var(--grey-semi-dark);
    --header-bg-color: var(--grey-dark);
    --active-bg-color: var(--blue);
    --active-bg-color-rgb: var(--rgb-blue);

    --special-bg-color_1 : var(--green-light);
    --special-bg-color_2 : var(--blue-light);
    --special-bg-color_3 : var(--purple-light);
    --special-bg-color_4 : var(--orange-light);

    --primary-text-color: var(--grey-dark);
    --primary-text-color-rgb: var(--rgb-grey-dark);
    --secondary-text-color: var(--white);
    --label-text-color : var(--grey-semi-dark);
    --label-text-color2 : var(--grey-semi-dark2);
    --label-text-color4 : var(--grey-semi-dark4);
    --active-text-color: var(--blue);

    --primary-element-color : var(--grey);
    --primary-element-color2 : var(--grey2);
    --secondary-element-color : var(--white);
    --primary-element-bg-color : var(--grey);
    --primary-element-active-color : var(--blue);
    --primary-table-bg-active : var(--blue-very-light);

    --tag-new-color: var(--blue);
    --tag-in-progress-color: var(--orange);
    --tag-ready-color: var(--green);
    --tag-blocked-color: var(--red);
    --tag-cancelled-color: var(--grey-semi-dark3);

    --overlay-color-rgb : var(--rgb-white);
    --overlay-dark-color-rgb : var(--rgb-black);

    --danger-color      : var(--red);
    --danger-color-rgb  : var(--rgb-red);
    --error-color       : var(--danger-color);
    --success-color     : var(--green);
    --success-color-rgb : var(--rgb-green);
    --warning-color     : var(--yellow);
    --warning-color-rgb : var(--rgb-yellow);

    --opacity100: 1;
    --opacity95: 0.95;
    --opacity70: 0.7;
    --opacity60: 0.6;
    --opacity40: 0.4;
    --opacity25: 0.25;
    --opacity20: 0.20;
    --opacity15: 0.15;
    --opacity10: 0.10;
    --opacity8:  0.08;
    --opacity6:  0.06;
    --opacity0: 0;

    --animation-speed: 0.3s;

    --antd-wave-shadow-color: var(--blue);

    --vh: 1px;

    --font-family : 'Titillium Web', sans-serif;
    --font-size   : 14px;
    --line-height : 1.5rem;

    --border-radius: 4px;
}

//::selection {
//    background: var(--primary-bg-color);
//}

body,
p{
    margin: 0;
}

ul{
    @extend %list-reset;
}

html, body, #root{
    min-height: 100%;
}

body{

    color: var(--primary-text-color);
    font: 400 var(--font-size) var(--font-family);
    line-height: var(--line-height);
}

a{
    text-decoration: none;
}


h1{
    font-size: 28px;
    font-weight: bold;
    line-height: 1.4em;
    margin: 0;
    color: var(--primary-text-color);
}

h3 {
    color: var(--header-bg-color);
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 8px;
    line-height: 1.75em;
}

h4{
    font-size: 14px;
    line-height: 1.5em;
    font-weight: 600;
    margin: 0;
    font-family: var(--font-family);

    &.inverted,
    &.blue{
        color: var(--active-text-color);
        text-transform: uppercase;
    }
}

h5{
    color: var(--label-text-color) !important;
    font: 600 12px / 1.7em var(--font-family) !important;
    letter-spacing: 0;
    text-transform: uppercase;
}


.logo{
    color: var(--active-text-color);
}

.grid{
    display: grid;
    grid-row-gap: 30px;
    grid-column-gap: 20px;

    &-six-col{
        @extend .grid;
        grid-template-columns: repeat(6, 1fr);
        grid-auto-rows: minmax(auto, auto);

    }

    &-five-col{
        @extend .grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: minmax(auto, auto);

    }

    &-four-col{
        @extend .grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(auto, auto);
    }

    &-three-col{
        @extend .grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: minmax(auto, auto);
    }

    &-two-col{
        @extend .grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(auto, auto);

        &-to-one{
            display: grid;
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    &-col{
        @extend .grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(auto, auto);
    }
}

h5.label{
    @extend .label;
}

.label{
    text-transform: uppercase;
    padding-bottom: 3px;
    color: var(--label-text-color);
    font: 600 12px/1.7em var(--font-family);
    letter-spacing: 0;
    margin: 0;
}

.ant-form{

    &-item{
        margin-bottom: 20px;

        &-label{
            text-transform: uppercase;
            padding-bottom: 2px !important;

            & > label{
                color: var(--label-text-color) !important;
                font: 600 12px/1.7em var(--font-family) !important;
                letter-spacing: 0;

            }
        }

        &-required{
            display: inline-flex;
            flex-direction: row-reverse;

            &:before{
                transform: translate(4px, -2px);
            }

            &:after{
                display: none;
            }
        }

        &-explain-error{
            color: var(--error-color);
            font-size: 13px;
        }

        &-has-error{
            .ant-input-number,
            .ant-input-password,
            .ant-input{
                border-color: var(--error-color) !important;
                box-shadow: none !important;
            }

            .ant-checkbox{
                .ant-checkbox-inner{
                    border-color: var(--error-color) !important;
                }
            }
        }

    }

    .form-actions{
        margin-top: 30px;
    }

    &.inner-forms{
        .ant-input{
            border-color: transparent !important;

            &:focus,
            &:focus-within,
            &:hover{
                border-color: var(--active-bg-color) !important;
            }
        }

        .form-actions{
            display: flex;
            justify-content: space-between;
        }

    }

    .ant-picker{
        @extend .ant-input;

        width: 100%;
        background-color: var(--primary-element-bg-color) !important;

        input{
            letter-spacing: -0.5px;
            font-weight: 600;
        }
    }

    .ant-input-number-group-wrapper,
    .ant-input-number{
        width: 100%;

        &-group-addon{
            background-color: var(--primary-element-bg-color) !important;
            font: 600 14px/1em var(--font-family);
            color: var(--label-text-color) !important;
            letter-spacing: 0;
            border: none !important;
        }

        &-input-wrap,
        &-input{
            width: 100%;
        }
    }
}


.ant-input{
    background-color: var(--primary-element-bg-color);
    font: 600 14px/1em var(--font-family);
    color: var(--primary-text-color);
    height: 34px;
    border-radius: var(--border-radius);

    &-number{
        @extend .ant-input;

        &-input{
            font: 600 14px/1em var(--font-family);
        }
    }

    &:focus,
    &:focus-within,
    &:hover{
        border-color: var(--active-bg-color);
    }

    &-affix-wrapper{
        border-radius: var(--border-radius);
        height: 34px;

        .ant-input{
            height: 100%;
        }

        &:focus,
        &:focus-within,
        &:hover{
            border-color: var(--active-bg-color);
        }

    }

    &-password{
        background-color: var(--primary-element-bg-color);
    }
}

.ant-btn{
    position: relative;
    min-height: 34px;
    min-width: 130px;
    border-radius: var(--border-radius);

    &:not(:disabled){
        &:focus-visible{
            outline: 2px solid var(--primary-element-active-color);
        }
    }

    &.ant-btn-sm{
        min-width: 40px;
    }

    &.popover-btn{
        min-width: 30px !important;
        width: 35px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;

        &.custom{
            width: unset !important;
        }

        .anticon{
            font-size: 18px;
        }
    }

    &-primary,
    &-default{
        .loader-item{
            display: inline-block;
            margin-right: 10px;
            position: relative;
            top: -1px;
        }

        .anticon-loading{
            font-size: 14px;
        }
    }

    &-text{
        min-height: 21px;
        min-width: initial;
        padding: 0 !important;
        background-color: transparent !important;
        /*font-weight: bold;*/
        letter-spacing: 0;
        display: flex;
        align-items: center;

        .loader-item{
            margin-right: 8px;
        }

        &.icon{
            min-width: 30px !important;
            height : 30px;
            min-height: 30px;
            width: 30px !important;

            justify-content: center;
        }

        &:before{
            display: none !important;
        }

        &:hover{
            color: var(--active-text-color) !important;
        }

        .anticon{
            font-size: 18px;
        }

        &.inverted,
        &.blue{
            color: var(--active-text-color) !important;

            &:hover{
                color: var(--primary-text-color) !important;
            }
        }
    }

    &-primary{
        min-width: 130px;
        background-color: var(--active-bg-color);
        font: 600 16px/1em var(--font-family);

        &:hover{
            background-color: var(--active-bg-color) !important;
        }

        .anticon-loading{
            color: var(--secondary-text-color);
        }

    }

    &-default{
        font: 600 16px/1em var(--font-family);

        &:hover{
            border-color: var(--active-bg-color) !important;
            color: var(--active-text-color) !important;
        }

    }

    &:before{
        @extend %transition;
        background-color: RGB(var(--overlay-color-rgb) / var(--opacity15));
        border-radius: var(--border-radius);
        content: "";
        position: absolute;
        opacity: var(--opacity0);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &:hover{
        &:before{
            opacity: var(--opacity100);
        }
    }

}

.ant-picker-clear{
    background-color: transparent !important;
}

.link{
    &-button{
        @extend .ant-btn, .ant-btn-primary;
        display: inline-flex;
        position: relative;
        justify-content: center;
        align-items: center;
        font: 600 16px/1em var(--font-family);
        color: var(--secondary-text-color) !important;
        padding-left: 10px;
        padding-right: 10px;

        .anticon{
            font-size: 18px;
            margin-right: 5px;
        }

    }

    &-disabled{
        opacity: var(--opacity20) !important;
        cursor: not-allowed !important;

        &:hover{
            color: inherit !important;
        }
    }

    &-text{
        display: inline-block;

        font: 400 14px/1em var(--font-family);
        color: var(--primary-text-color) !important;

        &.inverted,
        &.blue{
            color: var(--active-text-color) !important;

            &:hover{
                color: var(--primary-text-color) !important;
            }
        }

        & > .anticon{
            margin-right: 5px;
        }

        &:hover{
            color: var(--active-text-color) !important;
        }
    }
}

.ant-popover{
    &-inner{
        padding: 5px 0 !important;
        border-radius: 5px !important;
    }
}

.ant-modal{
    &-content{
        padding: 0 !important;
        border-radius: var(--border-radius) !important;
    }

    &-header{
        text-align: center;
        background-color: var(--primary-bg-color) !important;
        margin-bottom: 0 !important;

        h2{
            color: var(--active-text-color);
            font: 600 18px/1.4em var(--font-family) !important;
            margin: 0;
            padding: 18px 20px;
        }
    }

    &-body{
        min-height: 200px;
        max-height: calc(var(--vh) * 100 - 230px);
        overflow: auto;

        & > *:not(.modal-filter){
            padding: 18px;
        }

        .modal-filter{
            width: 100%;

            .ant-select{
                height: 40px;
                width: 100%;
            }

            .ant-select-selector{
                height: 40px;
                border-radius: initial !important;
                border: transparent !important;
                padding: 0 18px !important;
                box-shadow: none !important;
                background-color: RGB( var(--active-bg-color-rgb) / var(--opacity20) ) !important;

                input{
                    height: 40px !important;
                }
            }
        }

        .ant-modal-confirm-title{
            white-space: pre-line;
            overflow-wrap: break-word;
        }

        .loading{
            min-height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-footer{
        border-top: 1px solid var(--primary-element-color);
        padding: 22px 18px;
        margin-top: 0 !important;
    }

    &-close{
        top: -35px !important;
        right: 0 !important;
        width: 26px;
        height: 26px;
        background-color: transparent !important;

        &:hover{
            .anticon{
                color: var(--active-text-color);
            }
        }

        .anticon{
            @extend %transition;
            color: var(--secondary-text-color);
            font-size: 26px;
        }

    }

    &-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &.select-modal{
        width: 440px !important;

        .ant-modal-body{
            height: 400px !important;
        }

        .radio-select-wrap{
            height: 100%;
            max-height: calc( 400px - 76px );
            overflow: auto;
        }

        &.radio{
            .ant-radio-group{
                display: flex;
                flex-direction: column;
            }
        }
    }

    &-confirm{

        .ant-modal-body{
            min-height: auto;
        }

        &-paragraph{
            text-align: center;
            width: 100%;
            max-width: 100% !important;
        }

        &-btns{
            display: flex;
            margin-top: 12px;
            justify-content: center;
        }

    }

    div[aria-hidden="true"] {
        display: none;
    }
}

.ant-drawer{

    &-header{
        padding: 0 !important;
        background-color: var(--primary-bg-color) !important;
        margin-bottom: 0 !important;

        &-title{
            h2{
                color: var(--active-text-color);
                font: 600 18px/1.4em var(--font-family) !important;
                margin: 0;
                padding: 11px 38px 12px 20px;
            }
        }
    }

    &-close{
        margin-inline-end: initial !important;
        position: absolute;
        right: 11px;
        padding: 0;

        .anticon{
            font-size: 24px;
        }
    }

    &-content.withExtra {
        .drawer-content{
            padding-bottom: 80px !important;
            margin-bottom: 0 !important;
        }
    }

    &-body{
        width: 450px !important;
        padding: 0 !important;

        .drawer-filter{
            width: 100%;

            .ant-select{
                height: 40px;
                width: 100%;

                .ant-select-arrow{
                    right: 17px;
                }
            }

            .ant-select-selector{
                height: 40px;
                border-radius: initial !important;
                border: transparent !important;
                padding: 0 20px !important;
                box-shadow: none !important;
                background-color: RGB( var(--active-bg-color-rgb) / var(--opacity20) ) !important;

                input{
                    height: 40px !important;
                }
            }
        }

        .drawer-search{
            padding: 13px 21px 14px 18px;
            border-bottom: 1px solid var(--primary-element-color2);
        }

        .drawer-content{
            margin: 13px 10px;

            .ant-radio-group{
                .ant-radio-wrapper{
                    @extend %transition;
                    width: 100%;
                    margin-inline-end: initial;
                    padding: 3px 10px;
                    background-color: RGB(var(--rgb-blue) / var(--opacity0));

                    &:hover{
                        background-color: RGB(var(--rgb-blue) / var(--opacity8));
                        border-radius: 8px;
                    }

                    .img-wrap{
                        padding: 1px;
                        border: 1px solid var(--primary-element-color2);
                        width: 48px;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: var(--border-radius);
                        margin-right: 10px;

                        img{
                            display: inline-block;
                            width: 100%;
                            object-fit: contain;
                            height: 100%;
                        }
                    }

                    .label{
                        padding-bottom: 0 !important;
                    }

                    & > *:not(.ant-radio){
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .info{
                            display: flex;
                            flex-direction: column;
                            flex: 1;
                        }
                    }
                }
            }
        }
    }

    &-extra{
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 18px 21px;
        box-shadow: 0 -2px 8px 0 rgba(0,0,0,0.11);
        background-color: var(--secondary-bg-color);
        z-index: 10;

        .form-actions{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .loading{
        min-height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ant-radio{
    &-group{
        .ant-radio-wrapper + .ant-radio-wrapper{
            margin-top: 10px;
        }
    }

    &-wrapper{

        &:after{
            content: initial !important;
        }

        & span.ant-radio+*{
            font: 400 14px/1.4em var(--font-family) !important;
            color: var(--primary-text-color) !important;
        }

        &:hover{
            .ant-radio-inner{
                border-color: var(--active-bg-color) !important;
            }
        }

        &-checked{
            &:after{
                border-color: var(--active-bg-color) !important;
            }

            .ant-radio-inner{
                border-color: var(--active-bg-color) !important;
                background-color: var(--primary-element-bg-color) !important;

                &:after{
                    display: none;
                }

                &:before{
                    transform: scale(0.625);
                    opacity: 1;
                    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
                    background-color: var(--active-bg-color) !important;
                }
            }
        }

    }

    &-inner{
        @extend %transition;
        border-width: 2px !important;
        width: 20px !important;
        height: 20px !important;
        background-color: var(--primary-element-bg-color) !important;
        border-color: var(--primary-element-bg-color) !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        &:before{
            box-sizing: border-box;
            position: relative;
            display: block;
            width: 15px;
            height: 15px;
            background-color: #fff;
            border-radius: 16px;
            transform: scale(0);
            opacity: 0;
            transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
            content: "";
        }
    }
}

.ant-select{
    min-height: 34px;

    &-focused{

        .ant-select-selector{
            border-color: var(--active-bg-color) !important;
        }

    }

    &-selector{
        background-color: var(--primary-element-bg-color) !important;
        border-radius: var(--border-radius) !important;
        border-color: var(--primary-element-bg-color) !important;
    }

    &-selection-item{
        font: 600 14px/1.4em var(--font-family) !important;
        color: var(--primary-text-color) !important;
    }

    &-arrow{
        @extend %transition;
        transform: rotate(90deg);
    }

    &-open{
        .ant-select-arrow{
            transform: rotate(-90deg);
        }
    }


    &.select-status-new{
        .ant-select-selector{
            border: 1px solid var(--tag-new-color) !important;
            background-color: RGBA(var(--rgb-blue) / var(--opacity10)) !important;
        }

        .ant-select-selection-item{
            color: var(--tag-new-color) !important;
        }
    }

    &.select-status-in-progress{
        .ant-select-selector{
            border: 1px solid var(--tag-in-progress-color) !important;
            background-color: RGBA(var(--rgb-orange) / var(--opacity10)) !important;
        }

        .ant-select-selection-item{
            color: var(--tag-in-progress-color) !important;
        }
    }

    &.select-status-cancelled{
        .ant-select-selector{
            border: 1px solid var(--tag-cancelled-color) !important;
            background-color: RGBA(var(--rgb-grey-semi-dark3) / var(--opacity25)) !important;
        }

        .ant-select-selection-item{
            color: var(--tag-cancelled-color) !important;
        }
    }

    &.select-status-approved{
        .ant-select-selector{
            border: 1px solid var(--tag-ready-color) !important;
            background-color: RGBA(var(--rgb-green) / var(--opacity25)) !important;
        }

        .ant-select-selection-item{
            color: var(--tag-ready-color) !important;
        }
    }

    &.select-status-ready{
        .ant-select-selector{
            border: 1px solid var(--tag-ready-color) !important;
            background-color: RGBA(var(--rgb-green) / var(--opacity10)) !important;
        }

        .ant-select-selection-item{
            color: var(--tag-ready-color) !important;
        }
    }

    &.select-status-blocked{
        .ant-select-selector{
            border: 1px solid var(--tag-blocked-color) !important;
            background-color: RGBA(var(--rgb-red) / var(--opacity10)) !important;
        }

        .ant-select-selection-item{
            color: var(--tag-blocked-color) !important;
        }
    }

}

.ant-select-dropdown{
    border-radius: var(--border-radius) !important;

    .ant-select-item-option-selected{
        background-color: RGB( var(--active-bg-color-rgb) / var(--opacity20) ) !important;
    }
}

.search-field{
    min-width: 400px;

    .ant-input-affix-wrapper{
        background-color: var(--primary-element-bg-color);
    }

    .anticon{
        font-size: 22px;
        color: var(--active-text-color);
    }
}

.modal-filter-dropdown{
    font: 600 14px/1.4em var(--font-family) !important;
    border-radius: initial !important;
    padding: 0 !important;
    color: var(--primary-text-color);
    overflow: initial!important;
    box-shadow: none !important;

    .rc-virtual-list{
        border-top: 1px solid RGB( var(--active-bg-color-rgb) / var(--opacity60) ) !important;
        background-color: RGB( var(--active-bg-color-rgb) / var(--opacity20) ) !important;
        padding: 10px 0;
        position: relative;
        top: -4px !important;
        box-shadow: 0 6px 5px -3px RGB(var(--rgb-black)/var(--opacity20));

    }

    .ant-select-item-option{
        @extend %transition;
        background-color: transparent !important;
        letter-spacing: -0.1px !important;
        border-radius: inherit !important;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 18px;

        &-content{
            font: 600 14px/1.4em var(--font-family) !important;
        }

        &:hover{
            color: var(--active-text-color) !important;
        }

        &-selected{
            background-color: transparent !important;
        }
    }
}

.drawer-filter-dropdown{
    @extend .modal-filter-dropdown;
}

.ant-table{
    border: 1px solid var(--primary-element-color2);

    &-wrapper{
        .ant-table{
            border-radius: var(--border-radius) !important;

            .item-link{
                @extend %transition;
                font: bold 14px/1.2em var(--font-family) !important;
                color: var(--primary-text-color);
                text-decoration: underline;

                &:focus-within,
                &:hover{
                    color: var(--active-text-color);
                }
            }

            .table-link{
                @extend %transition;
                color: var(--active-text-color);
                font: 500 14px/1.2em var(--font-family) !important;
                margin-left: 5px;

                &:focus-within,
                &:hover{
                    color: var(--primary-text-color);
                    text-decoration: underline !important;
                }
            }
        }
    }

    &-tbody{
        .ant-table-measure-row{
            & > td {
                padding: 0 10px !important;
            }
        }
    }


    &-cell-row-hover{
        background-color: var(--primary-table-bg-active) !important;
    }

    thead{
        th, td{
            font: bold 12px/1.4em var(--font-family) !important;
            color: var(--label-text-color4) !important;
            background-color: var(--primary-element-bg-color) !important;
            padding: 15px 12px !important;

            &:before{
                display: none;
            }

            &:first-child{
                border-start-start-radius: var(--border-radius) !important;
            }
            &:last-child{
                border-start-end-radius: var(--border-radius) !important;
            }
        }

    }


    th, td{
        padding: 10px !important;
    }



    td{
        font: 400 14px/1.4em var(--font-family) !important;
    }
}

.loader-block {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.photo-carousel{
    border-radius: var(--border-radius);
    border: 1px solid var(--primary-element-color2);

    &-item{
        width: 100%;
        aspect-ratio: 1 / 1;
        position: relative;

        .img-wrap{
            height: 100%;
            display: flex !important;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: none;
            cursor: pointer;
            width: 100%;
            padding: 0;

            img{
               max-width: 100%;
                max-height: 100%;
            }
        }

        .ant-image{
            display: none;
        }

        .zoom-icon{
            @extend %transition;

            position: absolute;
            z-index: 1;
            right: 15px;
            bottom: 15px;
            font-size: 20px;
            padding: 5px;
            border-radius: var(--border-radius);
            background-color: RGB(var(--overlay-color-rgb) / var(--opacity15));
        }

        &:hover{
            .zoom-icon{
                color: var(--active-text-color);
                background-color: RGB(var(--overlay-color-rgb) / var(--opacity40));
            }
        }
    }

    &-thumb-nav{
        bottom: -71px !important;

        & > li{
            border-radius: var(--border-radius);
            border: 1px solid var(--primary-element-color2);
            width: 56px !important;
            height: 56px !important;

            img{
                object-fit: cover;
                display: block;
                width: 56px;
                height: 56px;
            }
        }
    }
}

.ant-collapse{

    .ant-collapse-header{
        align-items: center !important;


        &[aria-expanded="true"]{
            .plus-minus-toggle:before,
            .plus-minus-toggle:after{
                transform: rotate(0deg) !important;
            }
        }

        .ant-collapse-expand-icon{
            background-color: var(--active-bg-color);
            border-radius: 50%;
            width: 18px !important;
            height: 18px !important;
            margin-right: 15px;
            padding-inline-end: 0!important;

            .plus-minus-toggle{
                padding: 0;
                background-color: transparent;
                width: 100%;
                height: 100%;
                border: none;
                position: relative;
                cursor: pointer;

                &:before,
                &:after{
                    @extend %transition;
                    content: "";
                    background-color: var(--secondary-text-color);
                    display: block;
                    width: 10px;
                    height: 2px;
                    position: absolute;
                    left: 4px;
                }

                &:after{
                    transform: rotate(90deg);
                }

                &:before{
                    transform: rotate(-180deg);
                }
            }
        }
    }

    .ant-collapse-content-box{
        padding: 0 !important;
    }

    .ant-collapse-item-disabled{
        .version{
            color: var(--label-text-color);
        }

        .ant-collapse-expand-icon{
            background-color: var(--disabled-bg-color);

            .plus-minus-toggle{
                cursor: not-allowed !important;
            }
        }
    }
}

.count-field{
    display: flex;

    &-btn{
        @extend %transition;
        background-color: transparent;
        width: 24px;
        height: 24px;
        border-radius: var(--border-radius);
        border: 2px solid var(--primary-element-color2);
        padding: 0 !important;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;

        &:hover{
            color: var(--active-text-color);
            border-color: var(--primary-element-active-color);

            &:before,
            &:after{
                background-color: var(--active-text-color);
            }
        }
    }

    &-value{
        @extend %transition;
        min-width: 60px;
        text-align: center;
        display: inline-block;
        color: var(--primary-text-color);
        font-size: 16px;
        font-weight: 600;

        &.zero{
            color: var(--error-color);
        }
    }
}

.ant-image-preview{

    &-wrap{
        z-index: 2090 !important;
    }

    &-mask{
        z-index: 2080 !important;
    }

    &-operations-wrapper{
        z-index: 2095 !important;
    }
}

.tag-standard{
    border-radius: 13px;
    padding: 3px 12px 4px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    line-height: 1em;
    justify-content: center;

    &.new{
        border: 1px solid var(--tag-new-color);
        color: var(--tag-new-color);
        background-color: RGBA(var(--rgb-blue) / var(--opacity25));
    }

    &.in-progress{
        border: 1px solid var(--tag-in-progress-color);
        color: var(--tag-in-progress-color);
        background-color: RGBA(var(--rgb-orange) / var(--opacity25));
    }

    &.cancelled{
        border: 1px solid var(--tag-cancelled-color);
        color: var(--tag-cancelled-color);
        background-color: RGBA(var(--rgb-grey-semi-dark3) / var(--opacity25));
    }

    &.ready{
        border: 1px solid var(--tag-ready-color);
        color: var(--tag-ready-color);
        background-color: RGBA(var(--rgb-green) / var(--opacity25));
    }

    &.blocked{
        border: 1px solid var(--tag-blocked-color);
        color: var(--tag-blocked-color);
        background-color: RGBA(var(--rgb-red) / var(--opacity25));
    }
}

.importance{
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1em;

    & > .anticon{
        font-size: 21px;
        margin-right: 5px;
    }

    &.low{
        color: var(--grey-semi-dark);
    }

    &.normal{
        color: var(--blue);
    }

    &.high{
        color: var(--orange);
    }

    &.instantly{
        color: var(--red);
    }
}

.table-section-label{
    font-size: 14px !important;
    font-weight: 600 !important;
    min-width: 110px !important;
}

