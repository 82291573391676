.stands-table,
.stands-equipment-list-table
{

    .ant-table{
        border: none !important;
        border-bottom: 1px solid var(--primary-element-color2) !important;
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);

        &-content{
            border-left: 1px solid var(--primary-element-color2);
            border-radius: var(--border-radius);
        }

        table{
            border-top-right-radius: var(--border-radius);
            border-top: 1px solid var(--primary-element-color2);


            td:last-child,
            th:last-child{
                border-right: 1px solid var(--primary-element-color2);
            }

            th:first-child{
                border-radius: initial !important;

                .table-section-label{
                    border-left: none;
                }
            }

            th:last-child{
                border-top-right-radius: var(--border-radius);
            }
        }

        &-content{
            padding-top: 25px;
        }
    }

    .general-info + .equipment,
    .equipment + .graphics,
    .graphics + .cad-info,
    .cad-info + .task-info,
    .task-info + .actions{
        border-left: 1px solid var(--primary-element-color2);
    }

    .additional-orders{
        font-size: 13px;
        font-weight: 500;

        & > *{
            width: 250px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

        }

        .amount{
            margin-right: 4px;
            font-weight: 700;
        }

        .more{
            font-weight: normal;
        }

    }

    thead{
        th.equipment{
            background-color: var(--special-bg-color_1) !important;
        }

        th.graphics{
            background-color: var(--special-bg-color_2) !important;
        }

        th.cad-info{
            background-color: var(--special-bg-color_3) !important;
        }

        th.task-info{
            background-color: var(--special-bg-color_4) !important;
        }
    }

    tbody{
        .orientation{
            display: flex;
            align-items: center;
            font-weight: 600;

            .img-wrap{
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-right: 5px;

                img{
                    width: 100%;
                    border: 1px solid var(--primary-element-active-color);
                }
            }
        }
    }

    .actions{
        background-color: var(--secondary-bg-color) !important;
    }


    .table-section-label{
        color: var(--primary-text-color);
        border-top-right-radius: var(--border-radius);
        border-top-left-radius: var(--border-radius);
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        border: 1px solid var(--primary-element-color2);
        background-color: inherit;
        position: absolute;
        top: -25px;
        left: 0;
        min-width: 80px;
        height: 23px;
        display: flex;
        align-items: center;
        padding: 0 30px 0 10px;
    }
}

.stands-equipment-list-table{

    th.equipment{
        background-color: var(--special-bg-color_1) !important;

        .title{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .sku{

        }

        .total{
            color: var(--danger-color);
        }
    }

    .td.equipment{
        text-align: center;
    }

    .stand-info{
        color: var(--active-text-color);
        font-weight: 600;
    }
}