.message-list{
    &-item{


        & + &{
            border-top: 1px solid var(--primary-element-color2);
            padding-top: 5px;
            margin-top: 10px;
        }

        .info{
            display: flex;
            align-items: center;
            justify-content: space-between;

            .created{
                display: flex;

                .author{
                    font-weight: 600;
                }

                &-at{
                    color: var(--label-text-color);
                    margin-right: 5px;
                    display: flex;
                    align-items: center;

                    &:after{
                        content: "";
                        height: 1em;
                        width: 1px;
                        line-height: 1em;
                        background-color: var(--primary-element-color2);
                        display: block;
                        margin-left: 5px;
                    }
                }
            }
        }

        .message{
            color: var(--primary-text-color);
            font-family: var(--font-family);
            white-space: pre-line;
        }
    }

}

.filter-preset-radio-list{
    max-height: 300px;
    overflow: auto;

    .no-data{
        min-height: 160px;
    }

    .ant-radio-group{
        display: flex;
        flex-direction: column;

        .radio-row{
            margin: 0 3px !important;
            padding: 10px 0 !important;
            display: flex;
            flex: 1;
            justify-content: space-between;

            &:first-child{
                padding-top: 0 !important;
            }

            &:last-child{
                padding-bottom: 0 !important;
            }

            & + .radio-row{
                border-top: 2px solid var(--primary-element-color);
            }

            .ant-radio-wrapper {
                display: flex;
                flex: 1;
                align-items: center;
                margin-right: 0 !important;

                & > span:last-child{
                    display: flex;
                    justify-content: space-between;
                    flex: 1;

                    .title{
                        flex: 1;
                        align-items: center;

                        .anticon{
                            margin-left: 8px;
                            color: var(--active-text-color);
                        }
                    }

                    .user{
                        display: flex;
                        align-items: center;
                        width: 150px;
                        font-size: 12px;
                        color: var(--label-text-color);

                        &.me{
                            color: var(--active-text-color);
                        }

                        .anticon{
                            margin-right: 5px;
                            font-size: 14px;
                        }
                    }
                }
            }

            .action{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                font-size: 18px;
                padding-left: 5px;

                .anticon.lock{
                    color: var(--label-text-color);
                }
            }
        }
    }
}